import { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, CircularProgress } from '@mui/material';

const SuspenseLoader = ({
  isTableLoader,
  color = 'primary',
  size = 64,
}: {
  isTableLoader?: boolean;
  color?: 'primary' | 'secondary';
  size?: number;
}) => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={
        isTableLoader
          ? { width: '100%', height: '100%' }
          : {
              position: 'fixed',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
            }
      }
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size={size} disableShrink thickness={3} color={color} />
    </Box>
  );
};

export default SuspenseLoader;
