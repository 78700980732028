import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router';
import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import { useEffect } from 'react';
import { VERIFY_TOKEN } from '../graphql/courrier/courrierQueries';
import { IUser } from '../models/IUser';
import IResponseType from 'src/types/IResponseType';
const LoginWrapper = () => {
  const content = useRoutes(router);
  const navigate = useNavigate();
  const [getCurrentUser] = useLazyQuery<{
    verifyToken: IResponseType & { user: IUser };
  }>(VERIFY_TOKEN, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.verifyToken && !res?.verifyToken?.user?._id) navigate('/signin');
    },
  });

  useEffect(() => {
    getCurrentUser({
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        if (res?.verifyToken && !res?.verifyToken?.user?._id) navigate('/signin');
      },
    });
  }, [getCurrentUser]);

  return content;
};

export default LoginWrapper;
