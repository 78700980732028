import { Link } from 'react-router-dom';
import * as React from 'react';
import { ValidationRules, useFormContext } from 'react-hook-form';
import { InputProps } from '@mui/material/Input';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
export type IInputProps = Partial<InputProps & TextFieldProps & CheckboxProps> & {
  toggleShowPassword?: () => void;
  type?: string;
  isPasswordShown?: boolean;
  id?: string;
  label?: string;
  forgotPasswordMessage?: string;
  forgotPasswordLink?: string;
  rightIcon?: JSX.Element;
  leftIcon?: JSX.Element;
  name: string;
  validate?: ValidationRules;
  color?: string;
} & { containerStyle?: React.CSSProperties };

const ControlledInput: React.FC<IInputProps> = ({
  id,
  name,
  label,
  type,
  forgotPasswordLink,
  rightIcon,
  leftIcon,
  forgotPasswordMessage,
  validate,
  variant,
  containerStyle,
  ...props
}) => {
  const isPassword = type === 'password';
  const [isPasswordShown, toggleShowPassword] = React.useState(false);
  const handletoggleShowPassword = (): void => {
    toggleShowPassword(!isPasswordShown);
  };
  const passwordFieldType = isPasswordShown ? 'text' : 'password';

  const { errors, register } = useFormContext();

  const fieldError = errors[name];

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    event.preventDefault();
  };
  return (
    <div
      data-testid="inputFormGroup"
      className={`form-group ${fieldError ? 'u-has-error' : ''}`}
      style={containerStyle}
    >
      <label className="form-label" htmlFor={id}>
        <span className="d-flex justify-content-between align-items-center">
          {label}
          {isPassword && forgotPasswordMessage && (
            <Link className="link-muted  font-weight-normal" to={forgotPasswordLink || ''}>
              {forgotPasswordMessage}
            </Link>
          )}
        </span>
      </label>
      <div className="input-group">
        {leftIcon && (
          <div className="input-group-prepend">
            <span
              className={`input-group-text 
            ${fieldError ? 'border-danger' : ''}
            `}
              id="basic-addon2"
            >
              {leftIcon}
            </span>
          </div>
        )}
        {type === 'textarea' ? (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <TextField
            name={name}
            {...props}
            inputRef={validate ? register(validate) : register}
            className={`form-control ${fieldError ? 'is-invalid' : ''}`}
          />
        ) : type === 'checkbox' ? (
          <Checkbox
            name={name}
            id={id}
            {...props}
            inputRef={validate ? register(validate) : register}
            className={`form-control ${fieldError ? 'is-invalid' : ''}`}
          />
        ) : (
          <TextField
            variant={variant}
            name={name}
            id={id}
            {...props}
            inputRef={validate ? register(validate) : register}
            type={!isPassword ? type : passwordFieldType}
            className={`form-control ${fieldError ? 'is-invalid' : ''}`}
            style={{ backgroundColor: '#f1f1f1' }}
          />
        )}
        {rightIcon && (
          <div className="input-group-append">
            <span
              className={`input-group-text 
            ${fieldError ? 'border-danger' : ''}
            `}
              id="basic-addon2"
            >
              {rightIcon}
            </span>
          </div>
        )}
        {isPassword && (
          <div className="input-group-append">
            <button
              type="button"
              className="input-group-text"
              aria-label="toggle password visibility"
              onClick={handletoggleShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {!isPasswordShown ? (
                <i className="far fa-eye" />
              ) : (
                <i className="far fa-eye-slash" />
              )}
            </button>
          </div>
        )}
      </div>
      {fieldError && (
        <div
          data-testid="inputError"
          className="invalid-feedback"
          style={{ display: 'block' }}
        >
          {fieldError.message}
        </div>
      )}
    </div>
  );
};

export default ControlledInput;
