/* eslint-disable max-lines */
import { gql } from '@apollo/client';

export const GET_DRIVERS = gql`
  query (
    $limit: Int
    $page: Int
    $filter: String
    $sort: Int
    $sortBy: String
    $status: String
  ) {
    getAllDrivers(
      limit: $limit
      page: $page
      filter: $filter
      sort: $sort
      sortBy: $sortBy
      status: $status
    ) {
      status
      message
      drivers {
        page
        hasNextPage
        totalPages
        totalItems
        items {
          pickUpOrderCount
          deliveryOrderCount
          _id
          avatar
          name
          updatedAt
          createdAt
          phoneNumber
          handledCities {
            insideAreas
            remoteAreas
            city
          }
          handledCategory
          plainPasssword
        }
      }
    }
  }
`;

export const GET_DRIVERS_BY_CITY = gql`
  query ($cityName: String) {
    getDriversByCity(cityName: $cityName) {
      status
      message
      drivers {
        _id
        name
        avatar
        userId {
          _id
        }
        phoneNumber
        handledCities {
          city
        }
        pickUpOrders {
          orderItemId {
            _id
            totalCost
            estimatedDeliveryTime
            pickupAddress {
              pickUpCity
              pickUpAddress
            }
            items {
              _id
              quantity
              orderItemProductStatus {
                status
                createdAt
              }
              product {
                _id
                productName
                productImages
                productEIN
                productPrice
              }
              variant {
                variantEIN
                variantPrice
                variantImages
                _id
                variantName
              }
              notes
            }
            orderItemCode
            orderItemStatus {
              status
              createdAt
            }
            shopId {
              _id
              shopName
              shopLogo
              shopPickupAddresses {
                pickUpCity
                _id
                pickUpAddress
                pickUpContactLandNumber {
                  number
                  isVerified
                }
                pickUpContactMobileNumber {
                  number
                  isVerified
                }
              }
            }
          }
        }
        deliveryOrders {
          orderItemId {
            _id
            totalCost
            estimatedDeliveryTime
            pickupAddress {
              pickUpCity
              pickUpAddress
            }
            items {
              _id
              quantity
              orderItemProductStatus {
                status
                createdAt
              }
              product {
                _id
                productName
                productImages
                productEIN
                productPrice
              }
              variant {
                variantEIN
                variantPrice
                variantImages
                _id
                variantName
              }
              notes
            }
            orderItemCode
            orderItemStatus {
              status
              createdAt
            }
            shopId {
              _id
              shopName
              shopLogo
              shopPickupAddresses {
                pickUpCity
                _id
                pickUpAddress
                pickUpContactLandNumber {
                  number
                  isVerified
                }
                pickUpContactMobileNumber {
                  number
                  isVerified
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_DRIVER = gql`
  query ($driverId: ID) {
    deleteDriver(driverId: $driverId) {
      status
      message
    }
  }
`;

export const GET_DRIVER_BY_ID = gql`
  query ($driverId: ID) {
    getDriverById(driverId: $driverId) {
      driver {
        _id
        notes
        status
        avatar
        name
        phoneNumber
        plainPasssword
        handledCategory
        pickUpOrderCount
        deliveryOrderCount
        userId {
          userName
          userEmail
        }
        courrierId {
          _id
        }
        handledCities {
          insideAreas
          remoteAreas
          city
        }

        createdAt
        updatedAt
      }
      status
      message
    }
  }
`;

export const GET_DRIVER_CALENDER = gql`
  query ($driverId: ID) {
    getShipmentsCalenderData(driverId: $driverId) {
      message
      status
      shipmentData {
        pickup
        delivery
      }
    }
  }
`;

export const GET_DRIVER_DELIVERY_ORDERITEMS = gql`
  query (
    $limit: Int
    $page: Int
    $filter: String
    $sort: Int
    $sortBy: String
    $driverId: ID
  ) {
    getDriverDeliveryOrders(
      limit: $limit
      page: $page
      filter: $filter
      sort: $sort
      sortBy: $sortBy
      driverId: $driverId
    ) {
      status
      message
      orderItems {
        page
        hasNextPage
        totalPages
        totalItems
        items {
          billingAddress {
            fullName
            phone
            house
            address
            city
            area
            country
            mapLocation {
              coordinates
              type
              description
            }
          }
          createdAt
          updatedAt
          isReadyForShipping
          readinessPeriode
          _id
          orderId {
            _id
          }
          orderItemCode
          discountedPrice
          pickupAddress {
            pickUpCity
            pickUpAddress
          }
          items {
            quantity
            _id
            orderItemProductStatus {
              status
              _id
              createdAt
            }
            product {
              _id
              productName
              productImages
              productEIN
              productPrice
            }
            variant {
              variantPrice
              variantEIN
              variantImages
              _id
              variantName
            }
            notes
          }
          orderItemStatus {
            status
            _id
            createdAt
          }
        }
      }
    }
  }
`;
export const GET_DRIVER_PICKUP_ORDERITEMS = gql`
  query (
    $limit: Int
    $page: Int
    $filter: String
    $sort: Int
    $sortBy: String
    $driverId: ID
  ) {
    getDriverPickupOrders(
      limit: $limit
      page: $page
      filter: $filter
      sort: $sort
      sortBy: $sortBy
      driverId: $driverId
    ) {
      status
      message
      orderItems {
        page
        hasNextPage
        totalPages
        totalItems
        items {
          billingAddress {
            fullName
            phone
            house
            address
            city
            area
            country
            mapLocation {
              coordinates
              type
              description
            }
          }
          createdAt
          updatedAt
          isReadyForShipping
          readinessPeriode
          _id
          orderId {
            _id
          }
          orderItemCode
          discountedPrice
          pickupAddress {
            pickUpCity
            pickUpAddress
          }
          items {
            quantity
            _id
            orderItemProductStatus {
              status
              _id
              createdAt
            }
            product {
              _id
              productName
              productImages
              productEIN
              productPrice
            }
            variant {
              variantPrice
              variantEIN
              variantImages
              _id
              variantName
            }
            notes
          }
          orderItemStatus {
            status
            _id
            createdAt
          }
        }
      }
    }
  }
`;
